<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="activos10"><a href="">{{ traerInicio }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ traerTipoDeActivo.nombre }}</li>
                        </ol>
                    </nav>
                    <div class="card-tools">
                    </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="input-group">
                    <input v-model="buscarTexto" type="text" class="form-control" placeholder="Buscar...">
                    <button class="btn btn-sm" type="button" @click="aplicarFiltro"><i class="fa fa-search fa-2x"></i></button>
                    <button class="btn btn-sm" type="button" @click="borrarFiltro"><i class="fa fa-eraser fa-2x"></i></button>
                    <button class="btn btn-sm" type="button" @click="nuevoDato" v-show="esVisible"><i class="fa fa-plus-square fa-2x"></i></button>
                    <button class="btn btn-sm" type="button" @click="descargarExcel(listExport[0])"><i class="fa fa-download fa-2x"></i></button>
                </div>
                <Vuetable ref="vuetable"
                    :api-mode="false"
                    :fields="fields"
                    :per-page="perPage"
                    :data-manager="dataManager"
                    pagination-path="pagination"
                    :sort-order="sortOrder"
                    :css="css.table"
                    :show-sort-icons="true"
                    @vuetable:cell-clicked="onCellClicked"
                    @vuetable:pagination-data="onPaginationData"
                >
                    <div slot="acciones" slot-scope="props" v-show="esVisible">
                        <button class="btn btn-sm" type="button" @click="editaDato(props)"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-sm" type="button" @click="borraDato(props.rowData.id)"><i class="fa fa-trash"></i></button>
                    </div>
                </Vuetable>

              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                    <div class="row">
                        <div class="col align-self-start">
                            <vuetable-pagination-info ref="paginationInfo"
                            ></vuetable-pagination-info>
                        </div>
                        <div class="col align-self-end">
                            <vuetable-pagination ref="pagination"
                                :css="css.pagination"
                                @vuetable-pagination:change-page="onChangePage"
                            ></vuetable-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" v-show="!editmode">ACTIVO: Nuevo</h6>
                        <h6 class="modal-title" v-show="editmode">ACTIVO: Actualizar</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <!-- <form @submit.prevent="createUser"> -->

                    <form @submit.prevent="editmode ? modificaDato() : creaDato()">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Nombre</label>
                                <input v-model="form.nombre" type="text" name="nombre"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('nombre') }">
                                <has-error :form="form" field="nombre"></has-error>
                            </div>
                            <div class="form-group">
                                <label>{{ (!(this.traerTipoDeActivo.titulo == '' || this.traerTipoDeActivo.titulo == null)? this.traerTipoDeActivo.titulo : 'Descripción' ) }}<nav></nav></label>
                                <input v-model="form.descripcion" type="text" name="descripcion"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('descripcion') }">
                                <has-error :form="form" field="descripcion"></has-error>
                            </div>
                            <div v-if="traerTipoDeActivo.habilita2" class="form-group">
                                <label>{{ traerTipoDeActivo.titulo2 }}<nav></nav></label>
                                <input v-model="form.descripcion2" type="text" name="descripcion2"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('descripcion2') }">
                                <has-error :form="form" field="descripcion2"></has-error>
                            </div>
                            <div v-if="traerTipoDeActivo.habilita_activo_fijo" class="form-group">
                                <label>Activo Fijo<nav></nav></label>
                                <input v-model="form.activo_fijo" type="text" name="activo_fijo" max="10"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('activo_fijo') }">
                                <has-error :form="form" field="activo_fijo"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Empresas</label>
                                <select class="form-control" v-model="form.itempresa_id">
                                <option
                                    v-for="(emp,index) in empresas" :key="index"
                                    :value="index"
                                    :selected="index == form.itempresa_id">{{ emp }}</option>
                                </select>
                                <has-error :form="form" field="itempresa_id"></has-error>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal"><i class="fa fa-times"></i></button>
                            <button v-show="editmode" type="submit" class="btn btn-sm btn-outline-dark"><i class="fa fa-save"></i></button>
                            <button v-show="!editmode" type="submit" class="btn btn-sm btn-outline-dark"><i class="fa fa-save"></i></button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>


    </div>
  </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { saveExcel } from '@progress/kendo-vue-excel-export';

    import Vuetable from 'vuetable-2';
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        data () {
            return {
                orientacion : window.screen.orientation.type,

                userType: window.user.type,
                usuario_id : window.user.id,

                esVisible : (window.user.type == "viewer"? false: true),
                tipoDeUsuario : this.$gate.tipoDeUsuario(),

                editmode: false,
                form: new Form({
                    id : '',
                    itempresa_id : '',
                    ittipodeactivo_id : '',
                    nombre: '',
                    descripcion : '',
                    descripcion2 : '',
                    activo_fijo : ''
                }),
                empresas : [],
                listExport : [],

                perPage: window.user.paginado,
                data: [],
                fields : [
                    {
                        name: 'id',
                        title: 'ID',
                        sortField: 'id',
                        width: '5%'
                    },
                    {
                        name: 'tipodeactivo.icono',
                        title: '',
                        formatter (value) {
                            return `<i class="${value}"></i>`;
                        },
                        width: '5%'
                    },
                    {
                        visible: false, //traerTipoDeActivo.habilita_activo_fijo,
                        name: 'activo_fijo',
                        title: '#FA',
                        sortField: 'activo_fijo',
                        width: '10%'
                    },
                    {
                        name: 'nombre',
                        title: 'Nombre',
                        sortField: 'nombre',
                        width: '25%'
                    },
                    {
                        name: 'descripcion',
                        title: '',
                        sortField: 'descripcion'
                    },
                    {
                        visible: false, //this.traerTipoDeActivo.habilita2,
                        name: 'descripcion2',
                        title: '', //(this.traerTipoDeActivo.titulo2 == ''? 'Descripción': this.traerTipoDeActivo.titulo2),
                        sortField: 'descripcion2'
                    },
                    {
                        name: 'empresa.nombre',
                        title: 'Empresa',
                        sortField: 'empresa.nombre',
                        width: '20%'
                    },
                    {
                        name: 'acciones',
                        width: '12%'
                    }
                ],
                sortOrder: [
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-hovered table-responsive-0',
                        loadingClass: 'Cargando...',
                        ascendingIcon: 'fa fa-chevron-up',
                        descendingIcon: 'fa fa-chevron-down',
                        handleIcon: 'fa fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-backward',
                        next: 'fa fa-forward',
                        last: 'fa fa-step-forward',
                        },
                    }
                },
                buscarTexto : ''
            }
        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            }
        },
        computed: {
            ...mapGetters('breadcrumbs', [
                'traerInicio',
                'traerEmpresa',
                'traerTipoDeActivo',
                'traerFiltro'
            ]),
        },
        methods: {
            ...mapActions('breadcrumbs', {
                cambiarActivoAction : 'cambiarActivoAction',
                cambiarEmpresaAction: 'cambiarEmpresaAction',
                cambiarFiltroAction : 'cambiarFiltroAction'
            }),

            handleOrientationChange() {
                this.orientacion = window.screen.orientation.type;
/*
                if (this.orientation === "portrait-primary") { // portrait mode
                } else if (this.orientation === "landscape-primary") { // landscape mode
                }
*/
            },

            descargarExcel(reporte) {

                saveExcel({
                    data: reporte.resultado,
                    fileName: reporte.filename,
                    columns: JSON.parse(reporte.columns)
                });

            },


            loadRegistros(){
                axios.get("api/activo/traerSegunTipoDeActivo/" + this.traerTipoDeActivo.id + "/" + this.traerFiltro).then(
                    response => {this.data = response.data});
            },
            loadEmpresas(){
                axios.get("/api/empresa/list").then(({ data }) => (this.empresas = data.data));
            },
            loadExportar(){
                axios.get("/api/exporepo/listaUbicacionTipoDeUsuarioUsuarioEmpresaYTipDeActivo/3/" + this.userType + "/" + this.usuario_id + "/0/" + this.traerTipoDeActivo.id).then(
                    ({ data }) => {this.listExport = data});
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - INICIO
 *****************************************************************************/
            onCellClicked (data, field, event) {
                this.cambiarActivoAction(data.data);
                this.cambiarEmpresaAction(data.data.empresa);
                this.$router.push('activos12');
            },
            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },
            dataManager(sortOrder, pagination) {

                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                    );
                }

                // GABRIEL - Filtrar
                if (this.buscarTexto.length > 0) {
                    console.log("filtarPor:", this.buscarTexto.toLowerCase());
                    local = _.filter(
                        local,
                        (o) => o.nombre.toLowerCase().match(this.buscarTexto.toLowerCase())
                        );
                }
                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },
            onActionClicked(action, data) {
                console.log("slot actions: on-click", data.name);
            },

            aplicarFiltro() {
                this.$refs.vuetable.refresh()
            },
            borrarFiltro() {
                this.buscarTexto = '';
                this.$refs.vuetable.refresh()
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - FINAL
 *****************************************************************************/

/*****************************************************************************
 *  FUNCIONES DE REGISTRO - INICIA
 *****************************************************************************/

            modificaDato(){
                this.$Progress.start();

                this.form.put('/api/activo/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadRegistros();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editaDato(dato) {
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(dato.rowData);
            },
            nuevoDato() {
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            creaDato() {

                this.form.ittipodeactivo_id = this.traerTipoDeActivo.id;

                this.form.post('/api/activo')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadRegistros();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: '¡Ocurrió algún error! Inténtalo de nuevo.'
                    });
                })
            },
            borraDato(id){
                Swal.fire({
                    title: '¿Estas seguro?',
                    text: "¡No podrás revertir esto!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: '¡Sí, bórralo!'
                    }).then((result) => {

                        // Send request to the server
                            if (result.value) {
                                this.form.delete('api/activo/'+id).then(()=>{
                                        Swal.fire(
                                        '¡Eliminado!',
                                        '¡El Registro ha sido eliminado!',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadRegistros();
                                }).catch((data)=> {
                                    Swal.fire("¡Falló!", data.message, "warning");
                                });
                            }
                    })
            }

/*****************************************************************************
 *  FUNCIONES DE REGISTRO - FINAL
 *****************************************************************************/

        },
        mounted() {
            window.addEventListener(
                "orientationchange",
                this.handleOrientationChange
            );

            this.$Progress.start();
            this.loadEmpresas();
            this.loadExportar();
            this.loadRegistros();
            this.$Progress.finish();


            console.log('ACTIVOS11: Componente Montado.')
        },
        created() {
            if (this.$gate.isSingle()) {
                this.$router.push('dashboard');
            };

            if (this.traerTipoDeActivo.habilita_activo_fijo) {
                this.fields[2].visible = this.traerTipoDeActivo.habilita_activo_fijo;
            };

            this.fields[4].title = (!(this.traerTipoDeActivo.titulo == '' || this.traerTipoDeActivo.titulo == null)? this.traerTipoDeActivo.titulo : 'Descripción' );

            if (this.traerTipoDeActivo.habilita2) {
                this.fields[5].visible = this.traerTipoDeActivo.habilita2;
                this.fields[5].title   = (this.traerTipoDeActivo.titulo2 == ''? 'Descripción': this.traerTipoDeActivo.titulo2);
            };

        }
    }
</script>
