<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">LISTADO: {{ reporte.nombre }}</h3>
                            <div class="card-tools">
                                <button class="btn btn-sm" type="button" @click="descargarExcel()"><i class="fa fa-download fa-2x"></i></button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                        <h5 class="card-title">{{ reporte.descripcion }}</h5>
                        <Vuetable ref="vuetable"
                            :api-mode="false"
                            :fields="JSON.parse(reporte.fields)"
                            :per-page="perPage"
                            :data-manager="dataManager"
                            pagination-path="pagination"
                            :sort-order="JSON.parse(reporte.sortorder)"
                            :css="css.table"
                            :show-sort-icons="true"
                            @vuetable:pagination-data="onPaginationData"
                        >
                        </Vuetable>

                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer">
                            <div class="row">
                                <div class="col align-self-start">
                                    <vuetable-pagination-info ref="paginationInfo"
                                    ></vuetable-pagination-info>
                                </div>
                                <div class="col align-self-end">
                                    <vuetable-pagination ref="pagination"
                                        :css="css.pagination"
                                        @vuetable-pagination:change-page="onChangePage"
                                    ></vuetable-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Vuetable from 'vuetable-2';
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

    import { saveExcel } from '@progress/kendo-vue-excel-export';

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        data () {
            return {
                orientacion : window.screen.orientation.type,
                usuario_id : window.user.id,
                reporte_id : '0',

                perPage: window.user.paginado,
                reporte: [],
                data: [],

                css: {
                    table: {
                        tableClass: 'table table-striped table-hovered table-responsive-0',
                        loadingClass: 'Cargando...',
                        ascendingIcon: 'fa fa-chevron-up',
                        descendingIcon: 'fa fa-chevron-down',
                        handleIcon: 'fa fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-backward',
                        next: 'fa fa-forward',
                        last: 'fa fa-step-forward',
                        },
                    }
                },
            }
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            }
        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            }
        },
        computed: {
        },
        methods: {
            descargarExcel() {
                saveExcel({
                    data: this.reporte.resultado,
                    fileName: this.reporte.filename,
                    columns: JSON.parse(this.reporte.columns)
                });

            },

            loadReporte() {
                axios.get("/api/exporepo/traerReporte/" + this.reporte_id).then(
                    response => {
                        this.reporte = response.data;
                        this.data = response.data.resultado;
                    });
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - INICIO
 *****************************************************************************/
            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },
            dataManager(sortOrder, pagination) {

                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                    );
                }

                // GABRIEL - Filtrar
/*
                if (this.buscarTexto.length > 0) {
                    console.log("filtarPor:", this.buscarTexto.toLowerCase());
                    local = _.filter(
                        local,
                        (o) => o.nombre.toLowerCase().match(this.buscarTexto.toLowerCase())
                        );
                }
*/
                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },
            onActionClicked(action, data) {
                console.log("slot actions: on-click", data.name);
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - FINAL
 *****************************************************************************/


        },
        mounted() {
            this.reporte_id = this.$route.params.reporte_id;

            this.$Progress.start();
            this.loadReporte();
            this.$Progress.finish();

            console.log('LISTADO: Componente Montado.')
        },
        created() {

            if (this.$gate.isSingle()) {
                this.$router.push('dashboard');
            }

        }
    }


</script>
