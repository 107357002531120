<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                    <h3 class="card-title">BÚSQUEDA.</h3>
                    <div class="card-tools">
                    </div>
                    <div class="card-tools">
                    </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="input-group">
                    <input v-model="buscarTexto" type="text" class="form-control" placeholder="Buscar...">
                    <button class="btn btn-sm" type="button" @click="buscar()"><i class="fa fa-search fa-2x"></i></button>
                    <button class="btn btn-sm" type="button" @click="limpiar()"><i class="fa fa-eraser fa-2x"></i></button>
                </div>
                <Vuetable ref="vuetable"
                    :api-mode="false"
                    :fields="fields"
                    :per-page="perPage"
                    :data-manager="dataManager"
                    pagination-path="pagination"
                    :sort-order="sortOrder"
                    :css="css.table"
                    :show-sort-icons="true"
                    @vuetable:cell-clicked="onCellClicked"
                    @vuetable:pagination-data="onPaginationData"
                >
                </Vuetable>

              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                    <div class="row">
                        <div class="col align-self-start">
                            <vuetable-pagination-info ref="paginationInfo"
                            ></vuetable-pagination-info>
                        </div>
                        <div class="col align-self-end">
                            <vuetable-pagination ref="pagination"
                                :css="css.pagination"
                                @vuetable-pagination:change-page="onChangePage"
                            ></vuetable-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import Vuetable from 'vuetable-2';
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        data () {
            return {
                orientacion : window.screen.orientation.type,
                tipoDeUsuario : this.$gate.tipoDeUsuario(),

                perPage: window.user.paginado,
                data: [],
                fields : [
                    {
                        name: 'id',
                        title: 'ID',
                        sortField: 'id'
                    },
                    {
                        name: 'tipodeactivo.icono',
                        title: '',
                        formatter (value) {
                            return `<i class="${value}"></i>`;
                        }
                    },
                    {
                        name: 'nombre',
                        title: 'Nombre',
                        sortField: 'nombre'
                    },
                    {
                        name: 'empresa.nombre',
                        title: 'empresa',
                        sortField: 'empresa'
                    },
                ],
                sortOrder: [
                    {
                        field: 'nombre',
                        direction: 'asc'
                    }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-hovered table-responsive-0',
                        loadingClass: 'Cargando...',
                        ascendingIcon: 'fa fa-chevron-up',
                        descendingIcon: 'fa fa-chevron-down',
                        handleIcon: 'fa fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-backward',
                        next: 'fa fa-forward',
                        last: 'fa fa-step-forward',
                        },
                    }
                },
                buscarTexto : ''
            }
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            }
        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            }
        },
        computed: {
            ...mapGetters('breadcrumbs', [
                'traerInicio',
                'traerEmpresa',
                'traerTipoDeActivo',
                'traerActivo',
                'traerFiltro',
                'traerValorDeBusqueda'
            ]),

        },
        methods: {
            ...mapActions('breadcrumbs', {
                cambiarEmpresaAction     : 'cambiarEmpresaAction',
                cambiarTipoDeActivoAction: 'cambiarTipoDeActivoAction',
                cambiarActivoAction      : 'cambiarActivoAction',
                cambiarValorDeBusqueda   : 'cambiarValorDeBusqueda'
            }),

            limpiar() {
                this.buscarTexto = "";
                this.cambiarValorDeBusqueda(this.buscarTexto);
                this.data = [];
                this.$refs.vuetable.tableData = [];
                this.$refs.vuetable.refresh();
                this.$refs.pagination.refresh();
            },
            buscar() {
                this.cambiarValorDeBusqueda(this.buscarTexto);
                this.loadTraerSegunValorDeBusqueda();
                this.$refs.vuetable.refresh()
            },

            loadTraerSegunValorDeBusqueda() {
                axios.get("/api/activo/traerSegunValorDeBusqueda/" + this.tipoDeUsuario + "/" + this.buscarTexto).then(
                    response => {this.data = response.data});

            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - INICIO
 *****************************************************************************/
            onCellClicked (data, field, event) {
                this.cambiarEmpresaAction(data.data.empresa);
                this.cambiarTipoDeActivoAction(data.data.tipodeactivo);
                this.cambiarActivoAction(data.data);
                this.$router.push('activos4');
            },
            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },
            dataManager(sortOrder, pagination) {

                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                    );
                }

                // GABRIEL - Filtrar
/*
                if (this.buscarTexto.length > 0) {
                    console.log("filtarPor:", this.buscarTexto);
                    local = _.filter(
                        local,
                        (o) => o.nombre.toLowerCase().match(this.buscarTexto)
                        );
                }
*/
                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },
            onActionClicked(action, data) {
                console.log("slot actions: on-click", data.name);
            },

            aplicarFiltro() {
                this.$refs.vuetable.refresh()
            },
            borrarFiltro() {
                this.buscarTexto = '';
                this.$refs.vuetable.refresh()
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - FINAL
 *****************************************************************************/


        },
        mounted() {
            this.$refs.vuetable.refresh()
            console.log('BUSCAR: Componente Montado.')
        },
        created() {

            if (this.$gate.isSingle()) {
                this.$router.push('dashboard');
            }

            this.buscarTexto = this.traerValorDeBusqueda;
            if (this.buscarTexto.length > 0) {
                this.$Progress.start();
                this.loadTraerSegunValorDeBusqueda();
                this.$Progress.finish();
            }
        }
    }


</script>
