<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="activos"><a href="">{{ traerInicio }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ traerEmpresa.descripcion }}</li>
                        </ol>
                    </nav>
                <div class="card-tools">
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="input-group">
                    <input v-model="buscarTexto" type="text" class="form-control" placeholder="Buscar...">
                    <button class="btn btn-sm" type="button" @click="aplicarFiltro"><i class="fa fa-search fa-2x"></i></button>
                    <button class="btn btn-sm" type="button" @click="borrarFiltro"><i class="fa fa-eraser fa-2x"></i></button>
                </div>
                <Vuetable ref="vuetable"
                    :api-mode="false"
                    :fields="fields"
                    :per-page="perPage"
                    :data-manager="dataManager"
                    pagination-path="pagination"
                    :sort-order="sortOrder"
                    :css="css.table"
                    :show-sort-icons="true"
                    @vuetable:cell-clicked="onCellClicked"
                    @vuetable:pagination-data="onPaginationData"
                >
                </Vuetable>

              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                    <div class="row">
                        <div class="col align-self-start">
                            <vuetable-pagination-info ref="paginationInfo"
                            ></vuetable-pagination-info>
                        </div>
                        <div class="col align-self-end">
                            <vuetable-pagination ref="pagination"
                                :css="css.pagination"
                                @vuetable-pagination:change-page="onChangePage"
                            ></vuetable-pagination>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import Vuetable from 'vuetable-2';
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        data () {
            return {
                orientacion : window.screen.orientation.type,
                tipoDeUsuario: this.$gate.tipoDeUsuario(),
                tipoDeActivoAsociado: this.$gate.tipoDeActivoAsociado(),

                perPage: window.user.paginado,

                data: [],
                fields : [
                    {
                        name: 'id',
                        title: 'ID',
                        sortField: 'id'
                    },
                    {
                        name: 'icono',
                        title: '',
                        formatter (value) {
                            return `<i class="${value}"></i>`;
                        }
                    },
                    {
                        name: 'nombre',
                        title: 'Nombre',
                        sortField: 'nombre'
                    },
                ],
                sortOrder: [
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-hovered table-responsive-0',
                        loadingClass: 'Cargando...',
                        ascendingIcon: 'fa fa-chevron-up',
                        descendingIcon: 'fa fa-chevron-down',
                        handleIcon: 'fa fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-backward',
                        next: 'fa fa-forward',
                        last: 'fa fa-step-forward',
                        },
                    }
                },
                buscarTexto : ''
            }
        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            }
        },
        computed: {
            ...mapGetters('breadcrumbs', [
                'traerInicio',
                'traerEmpresa'
            ]),
        },
        methods: {
            ...mapActions('breadcrumbs', {
                cambiarTipoDeActivoAction: 'cambiarTipoDeActivoAction',
                cambiarFiltroAction : 'cambiarFiltroAction'
            }),

            handleOrientationChange() {
                this.orientacion = window.screen.orientation.type;
/*
                if (this.orientation === "portrait-primary") {
                    // portrait mode
                } else if (this.orientation === "landscape-primary") {
                    // landscape mode
                }
*/
            },

            loadRegistros() {
                axios.get("api/tipodeactivo/traerSegunTipoDeUsuario/" + this.tipoDeUsuario).then(
                    response => {
                        if (this.tipoDeActivoAsociado > 0) {

                            response.data.forEach(ele => {
                                if (ele.id == this.tipoDeActivoAsociado) {
                                    this.data.push(ele);
                                }
                            });
                        } else {
                            this.data = response.data
                        }
                    });
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - INICIO
 *****************************************************************************/
            onCellClicked (data, field, event) {
                this.cambiarTipoDeActivoAction(data.data);
                this.$router.push('activos3');
            },
            onPaginationData(paginationData) {
                this.$refs.pagination.setPaginationData(paginationData);
                this.$refs.paginationInfo.setPaginationData(paginationData);
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },
            dataManager(sortOrder, pagination) {

                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                    );
                }

                // GABRIEL - Filtrar
                if (this.buscarTexto.length > 0) {
                    console.log("filtarPor:", this.buscarTexto.toLowerCase());
                    local = _.filter(
                        local,
                        (o) => o.nombre.toLowerCase().match(this.buscarTexto.toLowerCase())
                        );
                }
                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },
            onActionClicked(action, data) {
                console.log("slot actions: on-click", data.name);
            },

            aplicarFiltro() {
                this.$refs.vuetable.refresh()
            },
            borrarFiltro() {
                this.buscarTexto = '';
                this.$refs.vuetable.refresh()
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - FINAL
 *****************************************************************************/

        },
        mounted() {
            window.addEventListener(
                "orientationchange",
                this.handleOrientationChange
            );

            this.$Progress.start();
            this.cambiarFiltroAction('');
            this.loadRegistros();
            this.$Progress.finish();

            console.log('ACTIVOS2: Componente Montado.')
        },
        created() {

        }
    }
</script>
