<template>
    <section class="content">
        <div class="container-fluid">
            <div class="input-group" v-show="esVisible">
                <input v-model="valorDeBusqueda" type="text" class="form-control" placeholder="Buscar...">
                <button class="btn btn-sm" type="button" @click="buscar()"><i class="fa fa-search fa-2x"></i></button>
                <button class="btn btn-sm" type="button" @click="limpiar()"><i class="fa fa-eraser fa-2x"></i></button>
            </div>

            <div class="row mt-2">
                <!-- ARRIBA - INICIO -->
                <div v-for="dashboard in ubicacion1" :key="dashboard.id" class="col-12 col-sm-6 col-md-3">
                    <div class="info-box" @click="saltarActivos11(dashboard.id)">
                        <span class="info-box-icon elevation-1">
                            <small><i :class="dashboard.icono"></i></small>
                        </span>

                        <div class="info-box-content">
                            <span class="info-box-text">
                                <small>{{ dashboard.nombre }}</small>
                            </span>
                            <span class="info-box-number">{{ dashboard.CANTIDAD }}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- ARRIBA - FIN -->
            </div>

            <div class="row">
                <!-- CENTRO - INICIO -->
                <div class="col-9">
                    <div class="card-group">
                        <div v-for="dashboard in ubicacion2" :key="dashboard.id" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div v-if="dashboard.type === 'valor'">
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title">{{ dashboard.titulo }}</h3>
                                        <div class="card-tools"><h4>{{ dashboard.resultado[0].VALOR }}</h4></div>
                                    </div>
                                    <div class="card-body">
                                        <va-chart :chart-config="dashboard.configuracion"></va-chart>
                                    </div>
                                    <div class="card-footer">
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="dashboard.type === 'pie' || dashboard.type === 'doughnut'">
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title">{{ dashboard.titulo }}</h3>
                                    </div>
                                    <div class="card-body">
                                        <va-chart :chart-config="dashboard.configuracion"></va-chart>
                                    </div>
                                    <div class="card-footer">
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="dashboard.type === 'bar'">
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title">{{ dashboard.titulo }}</h3>
                                    </div>
                                    <div class="card-body">
                                        <va-chart :chart-config="dashboard.configuracion"></va-chart>
                                    </div>
                                    <div class="card-footer">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- CENTRO - FIN -->

                <!-- COSTADO - INICIO -->
                <div class="col-3">
                    <div v-for="dashboard in ubicacion3" :key="dashboard.id" :class="'info-box bg-secondary text-center'">
                        <div class="container" @click="saltarActivos11(dashboard.id)">
                            <span class="info-box-icon">
                                <small><i :class="dashboard.icono"></i></small>
                            </span>

                            <div class="info-box-content">
                                <span class="info-box-text"><small>{{ dashboard.nombre }}</small></span>
                                <span class="info-box-number">{{ dashboard.CANTIDAD }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- COSTADO - FIN -->

            <!-- /.col -->
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VAChart from '../components/graficos/VAChart';

    export default {
        components: {
            'va-chart': VAChart
        },
        data () {
          return {
            ubicacion1  : [],
            ubicacion2  : [],
            ubicacion3  : [],
            datos  : [],
            orientacion : window.screen.orientation.type,
            tipoDeUsuario : this.$gate.tipoDeUsuario(),

            tipodeactivos : [],
            valorDeBusqueda : '',
            esVisible : (this.$gate.isSingle() || this.$gate.isSingleViewer() ? false: true),

            chartConfig: {
                type: 'doughnut',
                data: {
                labels: ['HTML', 'JavaScript', 'CSS'],
                datasets: [{
                    data: [56.6, 37.7, 4.1],
                    backgroundColor: ['#00a65a', '#f39c12', '#00c0ef'],
                    hoverBackgroundColor: ['blue', '#f39c12', '#00c0ef']
                }]
                },
                options: {
                responsive: true,
                legend: {
                    position: 'bottom',
                    display: true
                }
                }
            },

          }
        },
        computed: {
            ...mapGetters('breadcrumbs', [
                'traerInicio',
            ]),

        },
        methods: {
            ...mapActions('breadcrumbs', {
                cambiarInicioAction      : 'cambiarInicioAction',
                cambiarTipoDeActivoAction: 'cambiarTipoDeActivoAction',
                cambiarFiltroAction      : 'cambiarFiltroAction',
                cambiarValorDeBusqueda   : 'cambiarValorDeBusqueda'
            }),

            limpiar() {
                this.valorDeBusqueda = "";
            },
            buscar() {
                this.cambiarValorDeBusqueda(this.valorDeBusqueda);
                this.$router.push('buscar');
            },
            saltarActivos11(tipoDeActivo_id) {
                if (tipoDeActivo_id == 0) {
                    this.$router.push('activos');

                } else {

                    this.cambiarTipoDeActivoAction(this.tipodeactivos.find( tipo => tipo.id == tipoDeActivo_id ));
                    this.$router.push('activos11');
                }
            },

            handleOrientationChange() {
                this.orientacion = window.screen.orientation.type;
/*
                if (this.orientation === "portrait-primary") {
                    // portrait mode
                } else if (this.orientation === "landscape-primary") {
                    // landscape mode
                }
*/
            },

            loadRegistros(){
                // UBICACION: 1
                if (this.esVisible) {
                    axios.get("api/dashboard/traerTiposDeActivosSegunUbicacion/1/" + this.tipoDeUsuario).then(({ data }) => (this.ubicacion1 = data.data));
                    // UBICACION: 3
                    axios.get("api/dashboard/traerTiposDeActivosSegunUbicacion/3/" + this.tipoDeUsuario).then(({ data }) => (this.ubicacion3 = data.data));
                }
                // UBICACION: 2
                axios.get("api/dashboard/traerSegunUsuario/" + window.user.id + "/" + this.esVisible).then(({ data }) => (this.ubicacion2 = data.data));

                axios.get("/api/tipodeactivo/traerSegunTipoDeUsuario/" + this.tipoDeUsuario).then(({ data }) => (this.tipodeactivos = data));
                //this.armarDatos();

            },
            armarDatos() {

                console.log(this.ubicacion2.length);
                console.log(this.ubicacion2.nombre);
                console.log('=================');
                this.ubicacion2.forEach(ele => {
                    console.log('=================');
                    console.log(ele.id);


/*
            chartConfig: {
                type: 'doughnut',
                data: {
                labels: ['HTML', 'JavaScript', 'CSS'],
                datasets: [{
                    data: [56.6, 37.7, 4.1],
                    backgroundColor: ['#00a65a', '#f39c12', '#00c0ef'],
                    hoverBackgroundColor: ['blue', '#f39c12', '#00c0ef']
                }]
                },
                options: {
                responsive: true,
                legend: {
                    position: 'bottom',
                    display: true
                }
                }
            }
*/

                });

            }

        },
        mounted() {
          window.addEventListener(
              "orientationchange",
              this.handleOrientationChange
          );

          this.loadRegistros();

          console.log('DASHBOARD: Componente montado.')
        }
    }

</script>
