export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/configuraciones/Developer.vue').default },
    { path: '/users', component: require('./components/configuraciones/Users.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default },

    /* ACTIVOS IT */
    { path: '/atributos', component: require('./components/configuraciones/Atributos.vue').default },
    { path: '/elementos', component: require('./components/configuraciones/Elementos.vue').default },
    { path: '/empresas', component: require('./components/configuraciones/Empresas.vue').default },
    { path: '/tipodeactivos', component: require('./components/configuraciones/TipoDeActivos.vue').default },
    { path: '/caracteristicas', component: require('./components/configuraciones/Caracteristicas.vue').default },

    { path: '/activos', component: require('./components/Activos.vue').default },
    { path: '/activos2', component: require('./components/Activos2.vue').default },
    { path: '/activos3', component: require('./components/Activos3.vue').default },
    { path: '/activos4', component: require('./components/Activos4.vue').default },
    { path: '/activos10', component: require('./components/Activos10.vue').default },
    { path: '/activos11', component: require('./components/Activos11.vue').default },
    { path: '/activos12', component: require('./components/Activos12.vue').default },
    { path: '/buscar', component: require('./components/Buscar.vue').default },
    { path: '/reportes', component: require('./components/Reportes.vue').default },
    { path: '/listado/:reporte_id', component: require('./components/Listado.vue').default },
];
