<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                    <h3 class="card-title">REPORTES.</h3>
                    <div class="card-tools">
                    </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">

                    <div class="row">
                        <div v-for="reporte in reportes" :key="reporte.id" class="col-8 col-md-4 col-sm-1">
                            <div class="card text-center">
                                <div class="card-header">
                                    <h5 class="card-title">{{ reporte.nombre }}</h5>
                                </div>
                                <div class="card-body">
                                    <p class="card-text">{{ reporte.descripcion }}</p>
                                </div>
                                <div class="card-footer text-muted">
                                    <button class="btn btn-sm" type="button" @click="irAGrilla(reporte)"><i class="fa fa-check-circle fa-2x"></i></button>
                                    <button class="btn btn-sm" type="button" @click="descargarExcel(reporte)"><i class="fa fa-download fa-2x"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import { saveExcel } from '@progress/kendo-vue-excel-export';

    export default {
        data () {
            return {
                orientacion : window.screen.orientation.type,
                userType: window.user.type,
                usuario_id : window.user.id,

                reportes: [],
                data: []
            }
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            }
        },
        watch: {
        },
        computed: {

        },
        methods: {
            irAGrilla(reporte) {
                this.$router.push('listado/' + reporte.id);
            },
            descargarExcel(reporte) {

                saveExcel({
                    data: reporte.resultado,
                    fileName: reporte.filename,
                    columns: JSON.parse(reporte.columns)
                });

            },
            loadReportes() {
                axios.get("/api/exporepo/listaUbicacionTipoDeUsuarioUsuarioEmpresaYTipDeActivo/4/" + this.userType + "/" + this.usuario_id + "/X/0").then(
                    response => {this.reportes = response.data});
            },

        },
        mounted() {
            this.$Progress.start();
            this.loadReportes();
            this.$Progress.finish();

            console.log('REPORTES: Componente Montado.')
        },
        created() {

            if (this.$gate.isSingle()) {
                this.$router.push('dashboard');
            }

        }
    }
</script>
