export default class Gate{

    constructor(user){
        this.user = user;
    }

    isAdmin(){
        return this.user.type === 'admin';
    }

    isUser(){
        return this.user.type === 'user';
    }

    isAdminOrUser(){
        if(this.user.type === 'user' || this.user.type === 'admin'){
            return true;
        }
    }

    isViewer(){
        if(this.user.type === 'viewer'){
            return true;
        }
    }

    isSingle(){
        if(this.user.type === 'single'){
            return true;
        }
    }

    isSingleViewer(){
        if(this.user.type === 'single_viewer'){
            return true;
        }
    }

    empresaAsociada(){
        return this.user.itempresa_id;
    }

    tipoDeUsuario(){
        return this.user.type;
    }

    tipoDeActivoAsociado(){
        return this.user.ittipodeactivo_id;
    }

    passwordVisible(){
        return this.user.passwordvisible;
    }
}

