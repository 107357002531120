
export default {
  namespaced: true,
  state: {
    inicio          : 'Empresas',
    empresa         : {},
    tipoDeActivo    : {},
    activo          : {},
    filtro          : '',
    valorDeBusqueda : ''
  },
  getters: {
    traerInicio(state) {
      return state.inicio;
    },
    traerEmpresa(state) {
      return state.empresa;
    },
    traerTipoDeActivo(state) {
      return state.tipoDeActivo;
    },
    traerActivo(state) {
      return state.activo;
    },
    traerFiltro(state) {
      return state.filtro;
    },
    traerValorDeBusqueda(state) {
        return state.valorDeBusqueda;
      },
    },
  mutations: {
    cambiarInicio(state, nuevoInicio) {
      state.inicio = nuevoInicio;
    },
    cambiarEmpresa(state, nuevaEmpresa) {
      state.empresa = nuevaEmpresa;
    },
    cambiarTipoDeActivo(state, nuevoTipoDeActivo) {
      state.tipoDeActivo = nuevoTipoDeActivo;
    },
    cambiarActivo(state, nuevoActivo) {
      state.activo = nuevoActivo;
    },
    cambiarFiltro(state, nuevoFiltro) {
      state.filtro = nuevoFiltro;
    },
    cambiarValorDeBusqueda(state, nuevoValorDeBusqueda) {
        state.valorDeBusqueda = nuevoValorDeBusqueda;
      },
    },
  actions: {
    cambiarInicioAction(content, nuevoInicio) {
      content.commit('cambiarInicio', nuevoInicio);
    },
    cambiarEmpresaAction(content, nuevaEmpresa) {
      content.commit('cambiarEmpresa', nuevaEmpresa);
    },
    cambiarTipoDeActivoAction(content, nuevoTipoDeActivo) {
      content.commit('cambiarTipoDeActivo', nuevoTipoDeActivo);
    },
    cambiarActivoAction(content, nuevoActivo) {
      content.commit('cambiarActivo', nuevoActivo);
    },
    cambiarFiltroAction(content, nuevoFiltro) {
      content.commit('cambiarFiltro', nuevoFiltro);
    },
    cambiarValorDeBusqueda(content, nuevoValorDeBusqueda) {
        content.commit('cambiarValorDeBusqueda', nuevoValorDeBusqueda);
      },
  }
};
