<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="activos"><a href="">{{ traerInicio }}</a></router-link></li>
                            <li class="breadcrumb-item" aria-current="page"><router-link to="activos2"><a href="">{{ traerEmpresa.descripcion }}</a></router-link></li>
                            <li class="breadcrumb-item" aria-current="page"><router-link to="activos3"><a href="">{{ traerTipoDeActivo.nombre }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ traerActivo.nombre }}</li>
                        </ol>
                    </nav>
                    <div class="card-tools">
                    </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                    <div class="row mb-1">
                        <div class="col-2">
                            <i v-bind:class="traerActivo.tipodeactivo.icono + ' fa-2x'"></i>
                        </div>
                        <div class="col-10">
                            <h4 class="modal-title text-center">{{ traerActivo.descripcion }}: [#{{ traerActivo.id }}] {{ traerActivo.nombre }}</h4>
                        </div>
                    </div>

                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button :class='solapaActiva ? "nav-link active" : "nav-link"' id="nav-principal-tab" data-bs-toggle="tab" data-bs-target="#nav-principal" type="button" role="tab" aria-controls="nav-principal" :aria-selected="solapaActiva" @click="activarSolapa" :disabled="solapaActiva">Principal</button>
                            <button :class='!solapaActiva ? "nav-link active" : "nav-link"' id="nav-relaciones-tab" data-bs-toggle="tab" data-bs-target="#nav-relaciones" type="button" role="tab" aria-controls="nav-relaciones" :aria-selected="!solapaActiva" @click="activarSolapa" :disabled="!solapaActiva">Relaciones</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div :class='solapaActiva ? "tab-pane fade show active" : "tab-pane fade"' id="nav-principal" role="tabpanel" aria-labelledby="nav-principal-tab" v-show="solapaActiva">
                            <!-- PRINCIPAL -->
                            <div class="row">
                                <div class="col-9">
                                </div>
                                <div class="col-3">
                                    <div  v-if="esEditable" class="input-group" v-show="esVisible">
                                        <button class="btn btn-sm" type="button" v-bind:disabled="!edita" @click="cancelarEdicion()"><i class="fa fa-times fa-2x"></i></button>
                                        <button class="btn btn-sm" type="button" v-bind:disabled="edita"  @click="edita=!edita"><i class="fa fa-edit fa-2x"></i></button>
                                        <button class="btn btn-sm" type="button" v-bind:disabled="!edita" @click="actualizarCaracteristicas()"><i class="fa fa-save fa-2x"></i></button>
                                    </div>
                                </div>
                            </div>
                            <form>
                                <table class="table table-striped">
                                    <tbody>
                                        <tr v-for="caracteristicaAsignada in caracteristicasAsignadas" :key="caracteristicaAsignada.id">
                                            <td width="5%">
                                                <i v-bind:class="caracteristicaAsignada.icono"></i>
                                            </td>
                                            <td width="20%">
                                                {{ caracteristicaAsignada.nombre | truncate(30, '') }}


                                            </td>
                                            <td width="2%">
                                                <div v-if="caracteristicaAsignada.ittipodevalor_id === 9 && passwordVisible">
                                                    <button type="button" class="btn" @click="verPassword(caracteristicaAsignada.CA_ID)">
                                                        <i class="fa fa-eye"></i>
                                                    </button>
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 4">
                                                    <div v-if="caracteristicaAsignada.seleccion_multiple === 1">
                                                        <a href="#" @click="saltarActivos3(caracteristicaAsignada)"><i class="fas fa-list-alt grey"></i></a>
                                                    </div>
                                                    <div v-else>
                                                        <a href="#" @click="saltarActivos4p(caracteristicaAsignada)"><i class="fas fa-external-link-alt grey"></i></a>
                                                    </div>

                                                </div>

                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 8">
                                                        <a target="_blank" v-bind:href="'/storage/' + traerActivo.id + '_' + caracteristicaAsignada.CA_ID + '/' + caracteristicaAsignada.texto">
                                                            <i class="fa fa-archive"></i>
                                                        </a>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="caracteristicaAsignada.ittipodevalor_id === 1">
                                                    <!-- TEXTO -->
                                                    <div class="form-group">
                                                        <input v-model="caracteristicaAsignada.texto" v-bind:placeholder="caracteristicaAsignada.texto_default" type="text" name="texto" v-bind:disabled="!edita"
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('texto') }">
                                                        <has-error :form="form" field="texto"></has-error>
                                                    </div>
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 2">
                                                    <!-- NUMERO -->
                                                    <div class="form-group">
                                                        <input v-model="caracteristicaAsignada.numero" v-bind:placeholder="caracteristicaAsignada.numero_default" type="number" name="numero" v-bind:disabled="!edita"
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('numero') }">
                                                        <has-error :form="form" field="numero"></has-error>
                                                    </div>
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 3">
                                                    <!-- ATRIBUTO -->

                                                    <select class="form-control" v-model="caracteristicaAsignada.numero" v-bind:disabled="!edita">
                                                    <option
                                                        v-for="(ele, index) in filtrarElementos(caracteristicaAsignada.itseguntipodevalor_id)" :key="ele.id"
                                                        :value="ele.id"
                                                        :selected="index == caracteristicaAsignada.numero">{{ ele.nombre }}
                                                    </option>
                                                    </select>
                                                    <has-error :form="form" field="itseguntipodevalor_id"></has-error>

                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 4">
                                                    <!-- ACTIVO -->
                                                    <div v-if="caracteristicaAsignada.seleccion_multiple === 1">
                                                        <select multiple class="form-control" v-model="caracteristicaAsignada.auxiliar" v-bind:disabled="!edita">
                                                        <option
                                                            v-for="(act, index) in filtrarActivos(caracteristicaAsignada.itseguntipodevalor_id)" :key="act.id"
                                                            :value="act.id"
                                                            :selected="index == caracteristicaAsignada.auxiliar">{{ act.nombre }}
                                                        </option>
                                                        </select>
                                                        <has-error :form="form" field="itseguntipodevalor_id"></has-error>
                                                    </div>
                                                    <div v-else>
                                                        <select class="form-control" v-model="caracteristicaAsignada.numero" v-bind:disabled="!edita">
                                                        <option
                                                            v-for="(act, index) in filtrarActivos(caracteristicaAsignada.itseguntipodevalor_id)" :key="act.id"
                                                            :value="act.id"
                                                            :selected="index == caracteristicaAsignada.numero">{{ act.nombre }}
                                                        </option>
                                                        </select>
                                                        <has-error :form="form" field="itseguntipodevalor_id"></has-error>
                                                    </div>
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 5">
                                                    <!-- FECHA -->
                                                    <div class="form-group">
                                                        <input v-model="caracteristicaAsignada.texto" type="date" name="texto" v-bind:disabled="!edita"
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('texto') }" >
                                                        <has-error :form="form" field="texto"></has-error>
                                                    </div>
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 6">
                                                    <!-- HORA -->
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 7">
                                                    <!-- IMAGEN -->
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 8">
                                                    <!-- DOCUMENTO -->
                                                    <div class="form-group">
                                                        <input v-model="caracteristicaAsignada.texto" type="text" name="texto" v-bind:disabled="!edita"
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('texto') }" >

                                                        <input type="file" name="texto" v-bind:disabled="!edita"
                                                            accept="file/*" v-on:change="obtenerImagen"
                                                            class="form-control-file" :class="{ 'is-invalid': form.errors.has('texto') }"
                                                            v-bind:id="caracteristicaAsignada.CA_ID" >
                                                        <has-error :form="form" field="texto"></has-error>
                                                    </div>
                                                </div>
                                                <div v-else-if="caracteristicaAsignada.ittipodevalor_id === 9 && passwordVisible">
                                                    <!-- PASSWORD -->
                                                    <div class="form-group">
                                                        <input v-model="caracteristicaAsignada.texto" type="password" name="texto" v-bind:disabled="!edita"
                                                            class="form-control" :class="{ 'is-invalid': form.errors.has('texto') }" v-bind:id="caracteristicaAsignada.CA_ID">
                                                        <has-error :form="form" field="texto"></has-error>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <!-- NO CONTEMPLADO -->
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div :class='!solapaActiva ? "tab-pane fade show active" : "tab-pane fade"' id="nav-relaciones" role="tabpanel" aria-labelledby="nav-relaciones-tab" v-show="!solapaActiva">
                            <!-- RELACIONES -->
                            <div class="input-group">
                                <input v-model="buscarTexto" type="text" class="form-control" placeholder="Buscar...">
                                <button class="btn btn-sm" type="button" @click="aplicarFiltro"><i class="fa fa-search fa-2x"></i></button>
                                <button class="btn btn-sm" type="button" @click="borrarFiltro"><i class="fa fa-eraser fa-2x"></i></button>
                            </div>
                            <Vuetable ref="vuetable"
                                :api-mode="false"
                                :fields="fields"
                                :per-page="perPage"
                                :data-manager="dataManager"
                                pagination-path="pagination"
                                :sort-order="sortOrder"
                                :css="css.table"
                                :show-sort-icons="true"
                                @vuetable:cell-clicked="onCellClicked"
                                @vuetable:pagination-data="onPaginationData"
                            >
                            </Vuetable>
                        </div>
                    </div>

              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                    <div v-if="!solapaActiva">
                        <div class="row">
                            <div class="col align-self-start">
                                <vuetable-pagination-info ref="paginationInfo"
                                ></vuetable-pagination-info>
                            </div>
                            <div class="col align-self-end">
                                <vuetable-pagination ref="pagination"
                                    :css="css.pagination"
                                    @vuetable-pagination:change-page="onChangePage"
                                ></vuetable-pagination>
                            </div>
                        </div>
                    </div>
                  <div v-else>

                  </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    import Vuetable from 'vuetable-2';
    import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
    import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

    export default {
        components: {
            Vuetable,
            VuetablePagination,
            VuetablePaginationInfo,
        },
        data () {
            return {
                edita: false,
                solapaActiva : true,
                esVisible: (this.$gate.isViewer() ? false : true),
                esEditable: (this.$gate.isSingleViewer() ? false : true),

                tipoDeUsuario : this.$gate.tipoDeUsuario(),
                passwordVisible: this.$gate.passwordVisible(),

                form: new Form({
                    id                  : '',
                    itactivo_id         : '',
                    itcaracteristica_id : '',
                    numero              : '',
                    texto               : '',
                }),

                tipodeactivos: [],
                tipodevalores: [],
                atributos: [],
                elementos: [],
                activosSegunEmpresa: [],
                caracteristicasAsignadas : [],
                orientacion : window.screen.orientation.type,

                perPage: window.user.paginado,
                userType: window.user.type,
                userId: window.user.id,

                data: [],
                fields : [
                    {
                        name: 'id',
                        title: 'ID',
                        sortField: 'id'
                    },
                    {
                        name: 'tipodeactivo.icono',
                        title: '',
                        formatter (value) {
                            return `<i class="${value}"></i>`;
                        }
                    },
                    {
                        name: 'nombre',
                        title: 'Nombre',
                        sortField: 'nombre'
                    },
                ],
                sortOrder: [
                    {
                        field: 'nombre',
                        direction: 'asc'
                    }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-hovered table-responsive-0',
                        loadingClass: 'Cargando...',
                        ascendingIcon: 'fa fa-chevron-up',
                        descendingIcon: 'fa fa-chevron-down',
                        handleIcon: 'fa fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left',
                        wrapperClass: 'vuetable-pagination pull-right',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                        first: 'fa fa-step-backward',
                        prev: 'fa fa-backward',
                        next: 'fa fa-forward',
                        last: 'fa fa-step-forward',
                        },
                    }
                },
                buscarTexto : ''
            }
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            }
        },
        watch: {
            data(newVal, oldVal) {
                this.$refs.vuetable.refresh();
            }
        },
        computed: {
            ...mapGetters('breadcrumbs', [
                'traerInicio',
                'traerEmpresa',
                'traerTipoDeActivo',
                'traerActivo',
                'traerFiltro'
            ]),

        },
        methods: {
            ...mapActions('breadcrumbs', {
                cambiarTipoDeActivoAction: 'cambiarTipoDeActivoAction',
                cambiarActivoAction      : 'cambiarActivoAction',
                cambiarFiltroAction      : 'cambiarFiltroAction'
            }),
            activarSolapa(event) {
                this.solapaActiva = !this.solapaActiva;
            },
            obtenerImagen(event) {
                this.caracteristicasAsignadas.find((ele) => {
                    if(ele.CA_ID == event.srcElement.id) {
                        ele.texto = event.target.files[0].name;
                        ele.archivo = event.target.files[0];
                    }
                });
            },

            verPassword(CA_ID) {
                const password = document.getElementById(CA_ID);

                if (password.getAttribute('type') === 'password') password.setAttribute('type', 'text')
                else password.setAttribute('type', 'password')
            },

            cancelarEdicion() {
                this.edita = false;
                this.loadCaracteristicasAsignadas();
                this.loadRelaciones();
            },

            filtrarElementos(f) {
                return this.elementos.filter( e => {
                    return e.itatributo_id == f;
                });
            },

            filtrarActivos(f) {
                return this.activosSegunEmpresa.filter( a => {
                    return a.ittipodeactivo_id === f;
                });
            },

            saltarActivos4p(ca) {
                if (ca.numero > 0) {
                    let tip = {};
                    let act = {};

                    this.tipodeactivos.find((ele) => {
                        if(ele.id == ca.itseguntipodevalor_id) {
                            tip = ele;
                        }
                    });

                    this.activosSegunEmpresa.find((ele) => {
                        if(ele.id == ca.numero) {
                            act = ele;
                        }
                    });
                    this.cambiarTipoDeActivoAction(tip);
                    this.cambiarActivoAction(act);
                    this.loadCaracteristicasAsignadas()
                    this.loadRelaciones();
                    this.solapaActiva = true;
                }
            },

            saltarActivos4r(re) {
                if (re.id > 0) {
                    this.cambiarTipoDeActivoAction(re.tipodeactivo);
                    this.cambiarActivoAction(re);

                    this.loadCaracteristicasAsignadas();
                    this.loadRelaciones();
                    this.solapaActiva = true;
                }
            },

            saltarActivos3(ca) {
                let tip = {};

                this.tipodeactivos.find((ele) => {
                    if(ele.id == ca.itseguntipodevalor_id) {
                        tip = ele;
                    }
                });
                this.cambiarTipoDeActivoAction(tip);
                this.cambiarFiltroAction(ca.texto);

                this.$router.push('activos3');
            },
            actualizarCaracteristicas() {
                let ac = [];

                this.$Progress.start();
                this.caracteristicasAsignadas.forEach(car => {
                    if(car.ittipodevalor_id === 4) {
                        if(car.seleccion_multiple === 1) {
                            car.texto = "," + car.auxiliar.join();
                            car.numero = 0;
                        } else {
                            car.texto = "";
                        }
                    };
                    ac.push({
                        id                  : car.AC_ID,
                        itactivo_id         : this.traerActivo.id,
                        itcaracteristica_id : car.CA_ID,
                        ittipodevalor_id    : car.ittipodevalor_id,
                        numero              : car.numero,
                        texto               : car.texto,
                        orden               : car.orden,
                        archivo             : car.archivo
                    });
                });

                axios.put("/api/activo/guardarCaracteristicas", { datos : ac})
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.guardarArchivo();
                    this.$Progress.finish();
                    this.cancelarEdicion();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },

            guardarArchivo() {

                this.caracteristicasAsignadas.forEach(arc => {
                    let formData = new FormData();
                    formData.append('file', arc.archivo);
                    axios.post('/api/activo/upload/' + this.traerActivo.id + '/' + arc.CA_ID, formData,
                        {
                            Headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((response)=>{
                        Toast.fire({
                                icon: 'success',
                                title: response.data.message
                        });
                    })
                    .catch(()=>{
                        Toast.fire({
                            icon: 'error',
                            title: 'IMAGEN: ¡Ocurrió algún error! Inténtalo de nuevo.'
                        });
                    })

                });
            },

            loadTipoDeValores(){
                axios.get("/api/tipodevalor/list").then(({ data }) => (this.tipodevalores = data.data));
            },
            loadActivosSegunEmpresa(){
                axios.get("/api/activo/traerSegunEmpresa/" + this.traerEmpresa.id).then(({ data }) => (this.activosSegunEmpresa = data.data));
            },
            loadAtributos(){
                axios.get("/api/atributo/list").then(({ data }) => (this.atributos = data.data));
            },
            loadElementos(){
                axios.get("/api/elemento/list").then(({ data }) => (this.elementos = data.data));
            },
            loadCaracteristicasAsignadas(){
                axios.get("/api/activo/traerCaracteristicasAsignadas/" + this.traerActivo.id + "/" + this.userType + "/" + this.userId).then(({ data }) => (this.caracteristicasAsignadas = data.data));
            },
            loadRelaciones(){
                axios.get("/api/activo/traerRelaciones/" + this.traerEmpresa.id+ "/" + this.traerActivo.id).then(
                    response => {this.data = response.data});
            },
            loadTipoDeActivos(){
                axios.get("/api/tipodeactivo/traerSegunTipoDeUsuario/" + this.tipoDeUsuario).then(({ data }) => (this.tipodeactivos = data));
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - INICIO
 *****************************************************************************/
            onCellClicked (data, field, event) {
                if (data.data.id > 0) {
                    this.cambiarTipoDeActivoAction(data.data.tipodeactivo);
                    this.cambiarActivoAction(data.data);

                    this.loadCaracteristicasAsignadas();
                    this.loadRelaciones();
                    this.solapaActiva = true;
                }
            },
            onPaginationData(paginationData) {
                if (!this.solapaActiva) {
                    this.$refs.pagination.setPaginationData(paginationData);
                    this.$refs.paginationInfo.setPaginationData(paginationData);
                }
            },
            onChangePage(page) {
                this.$refs.vuetable.changePage(page);
            },
            dataManager(sortOrder, pagination) {

                if (this.data.length < 1) return;

                let local = this.data;

                // sortOrder can be empty, so we have to check for that as well
                if (sortOrder.length > 0) {
                    console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
                    local = _.orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                    );
                }

                // GABRIEL - Filtrar
                if (this.buscarTexto.length > 0) {
                    console.log("filtarPor:", this.buscarTexto.toLowerCase());
                    local = _.filter(
                        local,
                        (o) => o.nombre.toLowerCase().match(this.buscarTexto.toLowerCase())
                        );
                }
                pagination = this.$refs.vuetable.makePagination(
                    local.length,
                    this.perPage
                );
                console.log('pagination:', pagination)
                let from = pagination.from - 1;
                let to = from + this.perPage;

                return {
                    pagination: pagination,
                    data: _.slice(local, from, to)
                };
            },
            onActionClicked(action, data) {
                console.log("slot actions: on-click", data.name);
            },

            aplicarFiltro() {
                this.$refs.vuetable.refresh()
            },
            borrarFiltro() {
                this.buscarTexto = '';
                this.$refs.vuetable.refresh()
            },

/*****************************************************************************
 *  FUNCIONES PARA LA TABLA - FINAL
 *****************************************************************************/

        },
        mounted() {
            console.log('ACTIVOS4: Componente Montado.')
        },
        created() {
            this.$Progress.start();

            this.loadTipoDeValores();
            this.loadElementos();
            this.loadAtributos();
            this.loadTipoDeActivos();
            this.loadActivosSegunEmpresa();
            this.loadCaracteristicasAsignadas();
            this.loadRelaciones();

            this.$Progress.finish();

        }
    }

</script>
